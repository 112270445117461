// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-us-jsx": () => import("./../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-first-visit-jsx": () => import("./../../src/pages/first-visit.jsx" /* webpackChunkName: "component---src-pages-first-visit-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-patient-resources-jsx": () => import("./../../src/pages/patient-resources.jsx" /* webpackChunkName: "component---src-pages-patient-resources-jsx" */),
  "component---src-pages-providers-jsx": () => import("./../../src/pages/providers.jsx" /* webpackChunkName: "component---src-pages-providers-jsx" */),
  "component---src-pages-services-gynecology-jsx": () => import("./../../src/pages/services/gynecology.jsx" /* webpackChunkName: "component---src-pages-services-gynecology-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-menopause-jsx": () => import("./../../src/pages/services/menopause.jsx" /* webpackChunkName: "component---src-pages-services-menopause-jsx" */),
  "component---src-pages-services-postnatal-jsx": () => import("./../../src/pages/services/postnatal.jsx" /* webpackChunkName: "component---src-pages-services-postnatal-jsx" */),
  "component---src-pages-services-prenatal-jsx": () => import("./../../src/pages/services/prenatal.jsx" /* webpackChunkName: "component---src-pages-services-prenatal-jsx" */)
}

